import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin/admin.service';
declare var $: any;
@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.scss']
})
export class HomeContentComponent implements OnInit {
covers = [];
lastProjets = [];
  constructor(
    private adminService:AdminService
  ) { }

  ngOnInit() {
    this.getCovers();
    $(document).ready(function() {
      $('.carousel').carousel();
   })
   this.lastProjets = this.adminService.getLastProjets();
  }

  getCovers (){
    if(!this.adminService.covers.length)
    this.covers = this.adminService.getCovers();
    else 
    this.covers = this.adminService.covers;
  }

}
