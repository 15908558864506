import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { PageEvent, MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-projet-portfolio',
  templateUrl: './projet-portfolio.component.html',
  styleUrls: ['./projet-portfolio.component.scss']
})
export class ProjetPortfolioComponent implements OnInit {
  categories = [] ;
  projetPrev = [];
  id;
  nbprojet;
  nballprojet;
  pageIndex = 0;
  page : PageEvent;
  @ViewChild(MatPaginator,{static: false}) paginator: MatPaginator;
  constructor(
    private adminService : AdminService,
    private router : Router,
    private route : ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params)=>{
      this.pageIndex = 0 ;
      this.getProjetPrev(params.get('id'),this.pageIndex);
      this.id = params.get('id')

    })
    this.getCategories();
    this.adminService.getNbProjet()
      .then((doc)=>{
       if (doc.exists)
        this.nballprojet = doc.data().nbprojet;
       })
  }
  resetPage() {
    this.paginator.pageIndex = 0;
  }
  getProjetPrev (id,pageIndex){
    if(id == "all")
    {
      this.projetPrev = this.adminService.getAllProjectsPrev(pageIndex);
      this.adminService.getNbProjet()
      .then((doc)=>{
       if (doc.exists)
        this.nbprojet = doc.data().nbprojet;
       })
    }
    else
    {
      this.projetPrev = this.adminService.getProjetsPrevByCategorie(id,pageIndex);
      this.adminService.getCategorieById(id)
      .then((doc)=>{
        if (doc.exists)
         this.nbprojet = doc.data().nbprojet;
        })
    }
    
  }
  getCategories(){
    if(!this.adminService.categories.length)
    this.categories = this.adminService.getCategories2();
    else 
    this.categories = this.adminService.categories;
  }
  getTags(){
  }

  nextPage(event:PageEvent){
    this.pageIndex = event.pageIndex - event.previousPageIndex;
   this.getProjetPrev(this.id,this.pageIndex);
  }
}
