import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-projet-manager',
  host:{
    class:"flex-grow-1"
  },
  templateUrl: './projet-manager.component.html',
  styleUrls: ['./projet-manager.component.scss']
})
export class ProjetManagerComponent implements OnInit {
  projetPrev = [];
  idToDel;
  publish= true;
  constructor(
    private adminService : AdminService
  ) { 
  }
  ngOnInit() {
    this.getProjets();
  }

  getProjets(){
    this.projetPrev = this.adminService.getAllProjectsPrevForAdmin(this.publish);
  }
  getProjetPrev(){
    /*if(!this.adminService.projetsPrev.length)
    this.projetPrev = this.adminService.getAllProjectsPrev();
    else 
    this.projetPrev = this.adminService.projetsPrev;*/
  }

  supprimerProjet(id){
    this.idToDel = id;
  }

  confirmerSupp(){
    this.adminService.supprimerProjet(this.idToDel)
    .then(()=>{
      this.projetPrev = this.adminService.getAllProjectsPrev(0);
    })
    .catch((err)=>{console.log(err)})
    this.idToDel = '';
  }
}
