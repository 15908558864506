import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from './admin.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesManagerService implements Resolve<any> {

  constructor(private router: Router,private adminService : AdminService,) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {
      if(!this.adminService.categories.length)
    resolve(this.adminService.getCategories2())
    else 
     resolve(this.adminService.categories),
     reject;
    });
}
}
