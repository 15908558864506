import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cover-order',
  templateUrl: './cover-order.component.html',
  styleUrls: ['./cover-order.component.scss']
})
export class CoverOrderComponent implements OnInit {
  covers = [];
  cover: any;
  constructor(private adminService:AdminService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
   this.getCovers();
  }

  getCovers (){
    if(!this.adminService.covers.length)
    this.covers = this.adminService.getCovers();
    else 
    this.covers = this.adminService.covers;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.covers, event.previousIndex, event.currentIndex);
  }
  selectcover(cover){
    this.cover = cover;
  }

  onSubmit(){ 
    var promises = []
    this.covers.forEach((cov,i) => {
        if(cov.data['order'] != i )
        {
         promises.push(this.adminService.updateCoverOrder(cov.id,i)) 
        }
    })
    Promise.all(promises).then(() => {
      this._snackBar.open("Modification enregistrer",'', {
        duration: 3000,
         });
    })
  }

}
