import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AdminService } from '../admin/admin.service';
import {NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {NgxGalleryImage} from '@kolkov/ngx-gallery';
import {NgxGalleryAnimation} from '@kolkov/ngx-gallery';
@Component({
  selector: 'app-projet-detail',
  templateUrl: './projet-detail.component.html',
  styleUrls: ['./projet-detail.component.scss']
})
export class ProjetDetailComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];
  projet= <any>{};
  id;
  selectedImg="";
  constructor(
    private route : ActivatedRoute,
    private adminService : AdminService,
    private router : Router
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.adminService.getProjetById(this.id)
    .then((doc)=> {
      if (doc.exists) {
        this.projet = doc.data()
        console.log(this.projet);
        //------------------Gallery---------------------------
    this.galleryOptions = [
      {
          width: '100%',
          height: '600px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imagePercent: 80,
          thumbnailsPercent: 20,
          thumbnailsMargin: 20,
          thumbnailMargin: 20
      },
      // max-width 400
      {
          breakpoint: 400,
          width: '100%',
          height: '600px',
          preview: false
      }
  ];

  this.projet.images.forEach(img => {
     this.galleryImages.push(
       {
        small: 'https://lh3.googleusercontent.com/d/'+img,
        medium: 'https://lh3.googleusercontent.com/d/'+img,
        big: 'https://lh3.googleusercontent.com/d/'+img
       }
     )
  })
    //----------------------------------------------------
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    })
    .catch((error)=> {
      console.log('error loading ressources from firebase',error);
    });
    
  }

  onSelectImg(img){
    this.selectedImg = img;
  }

}
