import { Injectable, NgZone } from '@angular/core';
import { Observable,from } from 'rxjs';
import * as firebase from "firebase/app";
import "firebase/auth";
declare var gapi:any
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public static SESSION_STORAGE_KEY: string = 'accessToken';
  signin=false;
  user = null;
  user$ : Observable<firebase.User>
  constructor() { 
    this.user$ = new Observable((observer)=>{
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.user = user;
          observer.next(user)
        } else {
          this.user = null;
          observer.next (null);
        }
      }); 
    }) 
   }

  login(email : string , password : string){
   return from(firebase.auth().signInWithEmailAndPassword(email,password));
  }

  logout(){
    firebase.auth().signOut();
  }

  isLoggedIn (){
    return this.user$;
  }

   getToken(): string {
    return gapi.auth.getToken().access_token
}

  signIn(): void {
    gapi.auth2.getAuthInstance().signIn();
}

 signOut(): void {
  gapi.auth2.getAuthInstance().signOut();
}

  isSignInGoogle(): boolean{
    return gapi.auth2.getAuthInstance().isSignedIn.get();
  }
}
