import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form;
  sending=false ; 
  constructor(
    private fb:FormBuilder
  ) { 
    this.form = fb.group({
      nom : [''],
      email:['',[Validators.required, Validators.email]],
      sujet:[''],
      message:['',Validators.required]
    });
  }
  

  ngOnInit() {
  }


  get email() { return this.form.get('email'); }
  get sujet() { return this.form.get('sujet'); }
  get message() { return this.form.get('message'); }
}
