import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AdminService } from '../admin/admin.service';

@Component({
  selector: 'app-categorie-order',
  templateUrl: './categorie-order.component.html',
  styleUrls: ['./categorie-order.component.scss']
})
export class CategorieOrderComponent implements OnInit {
  categories: any[];

  constructor(private adminService:AdminService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.adminService.getCategories().then(cats =>{
      this.categories = cats;      
    });
    
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }

  onSubmit(){ 
    var promises = []
    this.categories.forEach((cat,i) => {
        if(cat.data['order'] != i )
        {
         promises.push(this.adminService.updateCategorieOrder(cat.id,i)) 
        }
    })
    Promise.all(promises).then(() => {
      this._snackBar.open("Modification enregistrer",'', {
        duration: 3000,
         });
    })
  }
}
