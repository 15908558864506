import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { AdminService } from '../admin/admin.service';
declare var $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  loggedIn;
  categories: any[];
  constructor(
    auth:AuthService,
    private adminService : AdminService,
  ) { 
    auth.user$.subscribe(user => {
      this.loggedIn = !!user;
    })
  }

  ngOnInit() {
  }

  toggle(){
    $(".collapse").collapse('toggle');
  }

}
