import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import * as firebase from "firebase/app";
import "firebase/auth";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
form;
onConnect=false;
  constructor(
    private fb:FormBuilder,
    private router : Router,
    private auth:AuthService
  ) {
    this.form = fb.group({
      email:['',[Validators.required, Validators.email]],
      password:['',Validators.required]
    });
   }

  ngOnInit() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.router.navigate(['dashboard'])
      } else {
      }
    }); 
  }

  get email() { return this.form.get('email'); }

  get password() { return this.form.get('password'); }

  onSubmit(){
    this.onConnect = true;
    this.auth.login(this.form.value.email,this.form.value.password).subscribe((res)=>{
      this.onConnect  = false;
      this.router.navigate(["/dashboard"])
    })
  }

}