import { Component } from '@angular/core';
import * as firebase from "firebase/app";
import "firebase/firestore"
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ATM Architecture';
  scroll = false;
  constructor(
  ){
    const firebaseConfig = {
      apiKey: "AIzaSyCAVUMtb8t-2ceLgRrgV6L_3vq6te1yocg",
      authDomain: "atmarchitecture-51b89.firebaseapp.com",
      databaseURL: "https://atmarchitecture-51b89.firebaseio.com",
      projectId: "atmarchitecture-51b89",
      storageBucket: "",
      messagingSenderId: "655447329862",
      appId: "1:655447329862:web:940a9dd9f88a4296d7ce47",
      measurementId: "G-6Q5LKL542J"
    };
    firebase.initializeApp(firebaseConfig);
    firebase.firestore().enablePersistence({synchronizeTabs:true});
    //firebase.analytics();
    $(function () {
      $(document).scroll(function () {
        var $nav = $(".fixed-top");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
      });
    });
  }
}
