
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { ProjetManagerComponent } from './admin/projet-manager/projet-manager.component';
import { CategoriesManagerComponent } from './admin/categories-manager/categories-manager.component';
import { AddProjetComponent } from './admin/add-projet/add-projet.component';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import { HomeContentComponent } from './home-content/home-content.component';
import { ProjetPortfolioComponent } from './projet-portfolio/projet-portfolio.component';
import { ProjetDetailComponent } from './projet-detail/projet-detail.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProjetEditComponent } from './projet-edit/projet-edit.component';
import { AuthGuard } from './auth/auth.guard';
import { ContactComponent } from './contact/contact.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AtmServicesComponent } from './atm-services/atm-services.component';
import { AboutComponent } from './about/about.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ProjetOrderComponent } from './admin/projet-order/projet-order.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CategorieOrderComponent } from './categorie-order/categorie-order.component';
import { CoverOrderComponent } from './admin/cover-order/cover-order.component';
import { CategoriesManagerService } from './admin/categories-manager.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    DashboardComponent,
    ProjetManagerComponent,
    CategoriesManagerComponent,
    AddProjetComponent,
    HomeContentComponent,
    ProjetPortfolioComponent,
    ProjetDetailComponent,
    ProjetEditComponent,
    ContactComponent,
    AtmServicesComponent,
    AboutComponent,
    ProjetOrderComponent,
    CategorieOrderComponent,
    CoverOrderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, 
    AngularEditorModule,
    LazyLoadImageModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatPaginatorModule,
    DragDropModule,
    NgxGalleryModule,
    RouterModule.forRoot([
      {
        path:'login',
        component:LoginComponent
      },
      {
        path:'dashboard',
        component:DashboardComponent,
        canActivate: [AuthGuard],
        children : [
          {
            path :'',
            canActivateChild: [AuthGuard],
            children : [
              {
                path:"projets",
                component:ProjetManagerComponent
              },
              {
                path:"categories",
                component:CategoriesManagerComponent,
                resolve: {data: CategoriesManagerService}
              },
              {
                path:"projetorder",
                component:ProjetOrderComponent
              },
              {
                path:"categorieorder",
                component:CategorieOrderComponent
              },
              {
                path:"coverorder",
                component:CoverOrderComponent
              },
              {
                path: "projets/add",
                component:AddProjetComponent
              },
              {
                path: "projets/edit/:id",
                component:ProjetEditComponent
              }
            ]
          }
        ]
      },
      {
        path:'',
        component:HomeComponent,
        children : [
          {
            path: '',
            component:HomeContentComponent
          },
          {
            path: 'projets/:id',
            component:ProjetPortfolioComponent
          },
          {
            path: 'projet/:id',
            component:ProjetDetailComponent
          },
          {
            path: 'contact',
            component:ContactComponent
          },
          {
            path: 'services',
            component:AtmServicesComponent
          },
          {
            path: 'about',
            component:AboutComponent
          }
        ]
      }
    ], { scrollPositionRestoration: 'enabled' }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
    