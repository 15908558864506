import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Validators, FormBuilder} from '@angular/forms';
import { AdminService } from '../admin.service';
import { DomSanitizer } from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import{Router} from '@angular/router'
import { AuthService } from 'src/app/auth.service';
import{wilayas} from '../../classe/wilaya';
declare var gapi:any
@Component({
  selector: 'app-add-projet',
  host:{
    class:"flex-grow-1"
  },
  templateUrl: './add-projet.component.html',
  styleUrls: ['./add-projet.component.scss']
})
export class AddProjetComponent implements OnInit {
  form;
  onUpload = false;
  done = false;
  titre : string = "";
  images = [];
  couverture;
  tag:string = "";
  tags = [];
  previewImgUrl = [];
  selectedImg ;
  coverPrev ;
  htmlContent ="";
  publish = false;
  selectedCat = [];
  categories = [];
  wilayas = wilayas;
  selectedWilaya;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
};
  constructor(
    private adminService : AdminService,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private fb:FormBuilder,
    private router : Router,
    public auth : AuthService
     ) { 
       this.form = fb.group({
        titre : ['',Validators.required],
        image:['',Validators.required]
      }
       )
     }

  ngOnInit() {
    this.getCategories();
  }

  onSubmit(){
    this.onUpload = true;
    this.adminService.uploudImages(this.images).subscribe(urls => {
      var projet = {
        "titre" : this.titre,
        "categories" : this.selectedCat,
        "images" : urls,
        "tags" : this.tags,
        "text": this.htmlContent,
        "publish" : this.publish,
        "wilaya": this.selectedWilaya || null
      }
      this.adminService.addProjet(projet)
      .then((doc)=> {
        var projPrev = {
          "titre":this.titre,
          "categories" : this.selectedCat,
          "image" : urls[0],
          "publish" : this.publish,
          "wilaya": this.selectedWilaya || null,
          "order": 0,
          "firstpage": false
        }
        this.adminService.addProjetPrev(projPrev,doc.id)
        .then(()=> {
          if(projPrev.publish)
          this.selectedCat.forEach(categorie => {
            this.adminService.IncProjetNbInCategorie(categorie);
          })
          if (this.couverture){
            this.adminService.uploadImageToCloud(this.couverture).subscribe(res =>{
              this.adminService.addCouverture(res['id'],doc.id,this.publish)
              .then(()=>{
                this.onUpload = false;
                this._snackBar.open("Projet ajouter avec succes",'', {
                duration: 3000,
                 });
                  this.router.navigate(['/dashboard/projets'])
              })
            })
          }
          else {
            this.onUpload = false;
            this._snackBar.open("Projet ajouter avec succes",'', {
              duration: 3000,
            });
            this.router.navigate(['/dashboard/projets'])
          }
        })
        .catch((error)=>{
          this.onUpload = false;
          this._snackBar.open("Erreur verifier votre connexion est ressayer",'', {
          duration: 3000,
        });
        console.log("error uploading to firebase",error)
        })
      })
      .catch((error)=> {
        this.onUpload = false;
        this._snackBar.open("Erreur verifier votre connexion est ressayer",'', {
          duration: 3000,
        });
        console.log("error uploading to firebase",error)
      })
    });
  }

  onPhotoSelected(event){
    var objectURL;
    //this.adminService.uploadImageToCloud(file).subscribe(res => {
    //  console.log(res);
    //})
    for(var i= 0;i < event.target.files.length  ;i++){
      this.images.push(event.target.files[i]);
      objectURL = URL.createObjectURL(event.target.files[i]);
      this.previewImgUrl.push(this.sanitizer.bypassSecurityTrustUrl(objectURL));
    }
  }
  onCouvertureSelected(event){
    var objectURL;
    this.couverture = event.target.files[0];
    objectURL = URL.createObjectURL(event.target.files[0]);
    this.coverPrev = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  removeImage(i){
    this.images.splice(i,1);
    this.previewImgUrl.splice(i,1);
  }
  removeCouverture(){
    this.couverture = null;
    this.coverPrev = null;
  }
  showImage(i){
    this.selectedImg = this.previewImgUrl[i];
  }
  getCategories (){
    if(!this.adminService.categories.length)
    this.categories = this.adminService.getCategories2();
    else 
    this.categories = this.adminService.categories;
}
  addTag(){
    if(this.tag != ""){
      this.tags.push(this.tag);
      this.tag = "";
    }
  }

  removeTag(i){
    this.tags.splice(i,1);
  }

  authGoogle (){ 
    this.auth.signIn();
  }
  logoutGoogle(){
    this.auth.signOut();
  }

  get image() { return this.form.get('image'); }
  get title() { return this.form.get('titre'); }

}
