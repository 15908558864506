import { Component, OnInit } from '@angular/core';
import {FormControl, Validators, FormBuilder} from '@angular/forms';
import { AdminService } from '../admin.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categories-manager',
  host:{
    class:"flex-grow-1"
  },
  templateUrl: './categories-manager.component.html',
  styleUrls: ['./categories-manager.component.scss']
})
export class CategoriesManagerComponent implements OnInit {
  form;
  categories = [];
  idToDel;
  constructor(
    private adminService : AdminService,
    private fb : FormBuilder,
    private route: ActivatedRoute,
  ) { 
    this.form = fb.group({
      name:['',Validators.required]
    });
  }

  ngOnInit() {
   // this.getCategories();
   this.route.data.subscribe((categories)=>{
     this.categories = categories.data
   })
  }

  onSubmit(){
    console.log(this.form.value);
    this.adminService.addCategorie(this.form.value)
    .then(()=> {
      this.categories = this.adminService.getCategories2();
    })
    .catch((err)=> {console.log(err);});
  }
  getCategories (){
    if(!this.adminService.categories.length)
    this.categories = this.adminService.getCategories2();
    else 
    this.categories = this.adminService.categories;
  }

  supprimerCategorie(id){
    this.idToDel = id;
  }

  confirmerSupp(){
    this.adminService.supprimerCategorie(this.idToDel)
    .then(()=>{
      this.categories = this.adminService.getCategories2();
    })
    .catch((err)=>{console.log(err)})
    this.idToDel = '';
  }

  get name() { return this.form.get('name'); }

}
