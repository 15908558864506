import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AdminService } from '../admin/admin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import{wilayas} from '../classe/wilaya';
@Component({
  selector: 'app-projet-edit',
  templateUrl: './projet-edit.component.html',
  styleUrls: ['./projet-edit.component.scss']
})
export class ProjetEditComponent implements OnInit {
  form;
  id;
  projet= <any>{};
  cover = null;
  coverSelected = null;
  coverPrev = null;
  categories = [];
  oldCategories=[];
  images = [];
  selectedImg1;
  selectedImg2;
  previewImgUrl = [];
  tag = "";
  order;
  firstpage;
  onUpload = false;
  wilayas = wilayas;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
};
  oldPublish: any;
  constructor(
    private route : ActivatedRoute,
    private adminService : AdminService,
    public auth : AuthService,
    private _snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private fb:FormBuilder,
    public router : Router,
  ) { 
    this.form = fb.group({
      titre : ['',Validators.required]
    }
     )
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.adminService.getProjetById(this.id)
    .then((doc)=> {
      if (doc.exists) {
        this.projet = doc.data()
        this.oldCategories = this.projet.categories;
        this.oldPublish = this.projet.publish;
        console.log(this.projet.wilaya);
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    })
    .catch((error)=> {
      console.log('error loading ressources from firebase',error);
    });
    this.adminService.getProjetPrevById(this.id)
    .then((doc)=>{
      if (doc.exists) {
        this.order = doc.data().order
        this.firstpage = !! doc.data()['firstpage']
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    })
    .catch((error)=> {
      console.log('error loading ressources from firebase',error);
    });
    this.adminService.getCoverById(this.id)
    .then((doc)=> {
      if (doc.exists) {
        this.cover = doc.data()
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
    })
    .catch((error)=> {
      console.log('error loading ressources from firebase',error);
    });
    this.getCategories();
  }

  onSubmit(){
    this.onUpload = true;
    this.updateProjet();
    
  }
  updateProjet(){
    var publishCondition = !(this.projet.publish == this.oldPublish);
    this.adminService.uploudImages(this.images).subscribe(urls => {
      if(urls)
      this.projet.images = this.projet.images.concat(urls);
      this.adminService.updateProjet(this.projet, this.id)
      .then((doc)=> {
        var projPrev = {
          "titre":this.projet.titre,
          "categories" : this.projet.categories,
          "image" : this.projet.images[0],
          "publish" : this.projet.publish,
          "wilaya":this.projet.wilaya || null,
          'order' : this.order,
          'firstpage': this.firstpage
        }
        this.adminService.addProjetPrev(projPrev,this.id)
        .then(()=> {
          if((this.projet.publish == true) && (this.oldPublish==true)){
            this.oldCategories.forEach(categorie => {
              this.adminService.DecProjetNbInCategorie(categorie);
            })
            this.projet.categories.forEach(categorie => {
              this.adminService.IncProjetNbInCategorie(categorie);
            })
          }
          if(publishCondition){
            if(this.projet.publish){
              this.projet.categories.forEach(categorie => {
                this.adminService.IncProjetNbInCategorie(categorie);
              })
              this.adminService.IncNbProjet();
            }
            else
            {
              this.oldCategories.forEach(categorie => {
                this.adminService.DecProjetNbInCategorie(categorie);
              })
              console.log('dec')
              this.adminService.DecNbProjet();
            }
            if(this.cover)
            this.adminService.updateCouverture(this.id,this.projet.publish);
          }
          if (this.coverSelected){
            this.adminService.uploadImageToCloud(this.coverSelected).subscribe(res =>{
              this.adminService.addCouverture(res['id'],this.id,this.projet.publish)
              .then(()=>{
                this.onUpload = false;
                this._snackBar.open("Projet modifier avec succes",'', {
                duration: 3000,
                 });
                  this.router.navigate(['/dashboard/projets'])
              })
            })
          }else if(!this.cover){
            this.adminService.supprimerCover(this.id)
            .then(()=> {
              this.onUpload = false;
                this._snackBar.open("Projet modifier avec succes",'', {
                duration: 3000,
                 });
                  this.router.navigate(['/dashboard/projets'])
            })
          }
          else {
            this.onUpload = false;
            this._snackBar.open("Projet modifier avec succes",'', {
              duration: 3000,
            });
            this.router.navigate(['/dashboard/projets'])
          }
        })
        .catch((error)=>{
          this.onUpload = false;
          this._snackBar.open("Erreur verifier votre connexion est ressayer",'', {
          duration: 3000,
        });
        console.log("error uploading to firebase",error)
        })
      })
      .catch((error)=> {
        this.onUpload = false;
        this._snackBar.open("Erreur verifier votre connexion est ressayer",'', {
          duration: 3000,
        });
        console.log("error uploading to firebase",error)
      })
    });
  }

  getCategories (){
    if(!this.adminService.categories.length)
    this.categories = this.adminService.getCategories2();
    else 
    this.categories = this.adminService.categories;
}

onPhotoSelected(event){
  var objectURL;
  //this.adminService.uploadImageToCloud(file).subscribe(res => {
  //  console.log(res);
  //})
  for(var i= 0;i < event.target.files.length  ;i++){
    this.images.push(event.target.files[i]);
    objectURL = URL.createObjectURL(event.target.files[i]);
    this.previewImgUrl.push(this.sanitizer.bypassSecurityTrustUrl(objectURL));
  }
}
onCouvertureSelected(event){
  var objectURL;
  this.coverSelected = event.target.files[0];
  this.cover = null;
  objectURL = URL.createObjectURL(event.target.files[0]);
    this.coverPrev = this.sanitizer.bypassSecurityTrustUrl(objectURL);
}

removeImage(i){
  this.images.splice(i,1);
  this.previewImgUrl.splice(i,1);
}
removeImageURL(i){
  this.projet.images.splice(i,1);
}
removeCouverture(){
  this.cover = null;
  this.coverSelected = null;
  this.coverPrev = null ;
}

addTag(){
  if(this.tag != ""){
    this.projet.tags.push(this.tag);
    this.tag = "";
  }
}

removeTag(i){
  this.projet.tags.splice(i,1);
}

showImagePrev(i){
  this.selectedImg2 = this.previewImgUrl[i];
}
showImage(i){
  this.selectedImg1 = this.projet.images[i];
}

authGoogle (){ 
  this.auth.signIn();
}
logoutGoogle(){
  this.auth.signOut();
}

  get title() { return this.form.get('titre'); }

}