export const wilayas = [
  {'id' : '1','code' : '1','nom' : 'Adrar'},
  {'id' : '2','code' : '2','nom' : 'Chlef'},
  {'id' : '3','code' : '3','nom' : 'Laghouat'},
  {'id' : '4','code' : '4','nom' : 'Oum El Bouaghi'},
  {'id' : '5','code' : '5','nom' : 'Batna'},
  {'id' : '6','code' : '6','nom' : 'Béjaïa'},
  {'id' : '7','code' : '7','nom' : 'Biskra'},
  {'id' : '8','code' : '8','nom' : 'Béchar'},
  {'id' : '9','code' : '9','nom' : 'Blida'},
  {'id' : '10','code' : '10','nom' : 'Bouira'},
  {'id' : '11','code' : '11','nom' : 'Tamanrasset'},
  {'id' : '12','code' : '12','nom' : 'Tébessa'},
  {'id' : '13','code' : '13','nom' : 'Tlemcen'},
  {'id' : '14','code' : '14','nom' : 'Tiaret'},
  {'id' : '15','code' : '15','nom' : 'Tizi Ouzou'},
  {'id' : '16','code' : '16','nom' : 'Alger'},
  {'id' : '17','code' : '17','nom' : 'Djelfa'},
  {'id' : '18','code' : '18','nom' : 'Jijel'},
  {'id' : '19','code' : '19','nom' : 'Sétif'},
  {'id' : '20','code' : '20','nom' : 'Saïda'},
  {'id' : '21','code' : '21','nom' : 'Skikda'},
  {'id' : '22','code' : '22','nom' : 'Sidi Bel Abbès'},
  {'id' : '23','code' : '23','nom' : 'Annaba'},
  {'id' : '24','code' : '24','nom' : 'Guelma'},
  {'id' : '25','code' : '25','nom' : 'Constantine'},
  {'id' : '26','code' : '26','nom' : 'Médéa'},
  {'id' : '27','code' : '27','nom' : 'Mostaganem'},
  {'id' : '28','code' : '28','nom' : 'M\'Sila'},
  {'id' : '29','code' : '29','nom' : 'Mascara'},
  {'id' : '30','code' : '30','nom' : 'Ouargla'},
  {'id' : '31','code' : '31','nom' : 'Oran'},
  {'id' : '32','code' : '32','nom' : 'El Bayadh'},
  {'id' : '33','code' : '33','nom' : 'Illizi'},
  {'id' : '34','code' : '34','nom' : 'Bordj Bou Arreridj'},
  {'id' : '35','code' : '35','nom' : 'Boumerdès'},
  {'id' : '36','code' : '36','nom' : 'El Tarf'},
  {'id' : '37','code' : '37','nom' : 'Tindouf'},
  {'id' : '38','code' : '38','nom' : 'Tissemsilt'},
  {'id' : '39','code' : '39','nom' : 'El Oued'},
  {'id' : '40','code' : '40','nom' : 'Khenchela'},
  {'id' : '41','code' : '41','nom' : 'Souk Ahras'},
  {'id' : '42','code' : '42','nom' : 'Tipaza'},
  {'id' : '43','code' : '43','nom' : 'Mila'},
  {'id' : '44','code' : '44','nom' : 'Aïn Defla'},
  {'id' : '45','code' : '45','nom' : 'Naâma'},
  {'id' : '46','code' : '46','nom' : 'Aïn Témouchent'},
  {'id' : '47','code' : '47','nom' : 'Ghardaïa'},
  {'id' : '48','code' : '48','nom' : 'Relizane'}
]