import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
year:number;
  constructor() {
    this.year = new Date().getFullYear();
   }

  ngOnInit() {
    $(document).ready(function(){

      if($('.brands_slider').length)
      {
      var brandsSlider = $('.brands_slider');
      
      brandsSlider.owlCarousel(
      {
      loop:true,
      autoplay:true,
      autoplayTimeout:5000,
      nav:false,
      dots:false,
      autoWidth:true,
      items:8,
      margin:42
      });
      
      if($('.brands_prev').length)
      {
      var prev = $('.brands_prev');
      prev.on('click', function()
      {
      brandsSlider.trigger('prev.owl.carousel');
      });
      }
      
      if($('.brands_next').length)
      {
      var next = $('.brands_next');
      next.on('click', function()
      {
      brandsSlider.trigger('next.owl.carousel');
      });
      }
      }
      
      
      });
  }

}
