import { Injectable } from '@angular/core';
import { Observable,from, of, Subject,forkJoin} from 'rxjs';
import { switchMap,map} from 'rxjs/operators';
import * as firebase from "firebase/app";
import "firebase/firestore"
import "firebase/functions"
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
const CLOUDINARY_URL = 	"https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id";
const CLOUDINARY_UPLOUD_PRESET = "pudg1yjq";
@Injectable({
  providedIn: 'root'
})
export class AdminService {

  httpOptions ;
  metadata = {
    'mimeType': '	image/jpeg', // mimeType at Google Drive
    'parents': ['1Xx1rI6OJ3T-vqhazNcOwKH_xvaU2k5ic'], // Folder ID at Google Drive
};
  projet;
  categories = [];
  projetsPrev = [];
  lastProjet;
  firstProjet;
  covers = [];
  function = firebase.functions();
  db = firebase.firestore();
  categorieRef = this.db.collection("categorie");
  projetRef = this.db.collection("projet");
  projetPrevRef = this.db.collection("projetprev");
  coverRef = this.db.collection("cover");
  counterRef = this.db.collection("counter");
  constructor(
    private http:HttpClient,
    private auth : AuthService
  ) { 

  }

  addCategorie(categorie) {
    return this.categorieRef.add({
      name:categorie.name.toLowerCase(),
      nbprojet:0,
      order : 0
    })
  }
  addProjet (projet){
    if(projet.publish)
    this.counterRef.doc('projet').update({
      nbprojet : firebase.firestore.FieldValue.increment(1)
    })
    return this.projetRef.add(projet);    
  }
  updateProjet (projet,id){
    return this.projetRef.doc(id).set(projet);    
  }
  addProjetPrev(projet,id){
    return this.projetPrevRef.doc(id).set(projet);
  }
  addCouverture(img , id, publish){
     return this.coverRef.doc(id).set({
       "image" : img,
       "publish": publish,
       "order" : 999
      })
  }
  updateCouverture(id,publish){
    return this.coverRef.doc(id).update({
      publish: publish,
      order:999
     })
  }
  updateProjetOrder(id,order){
    return this.projetPrevRef.doc(id).update({
      order : order
    })
  }
  updateCategorieOrder(id,order){
    return this.categorieRef.doc(id).update({
      order : order
    })
  }
  updateCoverOrder(id,order){
    return this.coverRef.doc(id).update({
      order : order
    })
  }
  getProjetsPrevByCategorie(categorie,pageIndex){
    console.log('Service bringing projects from firebase !')
    this.projetsPrev = [];
    if(!pageIndex){
    this.projetPrevRef
    .where("categories","array-contains", categorie)
    .where("publish","==",true)
    .orderBy('order','asc')
    .limit(12)
    .get()
    .then(res => {
      this.lastProjet = res.docs[res.docs.length-1]
      res.forEach(doc => {
        this.projetsPrev.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    }else {
      this.projetPrevRef.where("categories","array-contains", categorie)
      .orderBy('order','asc')
    .startAfter(this.lastProjet)
    .limit(12)
    .get()
    .then(res => {
      this.lastProjet = res.docs[res.docs.length-1]
      res.forEach(doc => {
        this.projetsPrev.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    }
    return this.projetsPrev;
  }
  getAllProjectsPrevForAdmin(publish){
    this.projetsPrev = []
    this.projetPrevRef
    .where("publish","==", publish)
    .orderBy('order','asc')
    .get()
    .then(res => {
      res.forEach(doc => {
        this.projetsPrev.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    return this.projetsPrev;
  }
  getAllPrjPrev(){
    this.projetsPrev = []
    return this.projetPrevRef
    .where('publish','==', true)
    .orderBy('order','asc')
    .get()
    .then(res => {
      res.forEach(doc => {
        this.projetsPrev.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
      return this.projetsPrev;
    })
  }
  getAllProjectsPrev(pageIndex){
    console.log('Service bringing projects from firebase !')
    this.projetsPrev = [];
    var query = this.projetPrevRef
    .where("publish","==",true)
    .orderBy('order','asc')
    if(pageIndex == 0){
      query
    .limit(12)
    .get()
    .then(res => {
      this.lastProjet = res.docs[res.docs.length-1]
      res.forEach(doc => {
        this.projetsPrev.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    }else if (pageIndex > 0) {
      query
    .startAfter(this.lastProjet)
    .limit(12)
    .get()
    .then(res => {
      this.lastProjet = res.docs[res.docs.length-1]
      this.firstProjet = res.docs[0]
      res.forEach(doc => {
        this.projetsPrev.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    }else {
      this.projetPrevRef
    .where("publish","==", true)
    .orderBy('order','desc')
    .startAfter(this.firstProjet)
    .limit(12)
    .get()
    .then(res => {
      this.firstProjet = res.docs[res.docs.length-1]
      this.lastProjet = res.docs[0]
      res.forEach(doc => {
        this.projetsPrev.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
      this.projetsPrev.reverse();
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    }

    return this.projetsPrev;
  }
  getLastProjets(){
    var projets = [];
    this.projetPrevRef
    .where("publish","==", true)
    .where("firstpage",'==',true)
    .limit(3)
    .get()
    .then((docs)=>{
      docs.forEach(doc => {
        projets.push({
          "id": doc.id,
          "data":doc.data()
        });
    })
  })
  .catch((error)=> {
    console.log('error loading ressources from firebase',error);
  })
  return projets;
}
  getCoverById (id) {
    return this.coverRef.doc(id)
    .get()
  }
  getCovers(){
    console.log('Service bringing projects from firebase !')
    this.covers = [];
    this.coverRef
    .where("publish","==",true)
    .orderBy("order","asc")
    .get()
    .then(res => {
      res.forEach(doc => {
        this.covers.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    return this.covers;
  }
  getProjetById(id){
    return this.projetRef.doc(id)
    .get()
  }
  getProjetPrevById(id){
    return this.projetPrevRef.doc(id)
    .get()
  }
  getCategorieById(id){
    return this.categorieRef.doc(id)
    .get()
  }
  getNbProjet() {
    return this.counterRef.doc('projet')
    .get()
  }
  getCategories(){
    console.log('Service bringing categories from firebase1 !')
    this.categories = [];
    return this.categorieRef
    .orderBy('order','asc')
    .get()
    .then(res => {
      res.forEach((doc)=> {
        this.categories.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
      return this.categories;
    })
    
  }
  getCategories2(){
    console.log('Service bringing categories from firebase2 !')
    this.categories = [];
    this.categorieRef
    .orderBy('order','asc')
    .get()
    .then(res => {
      res.forEach((doc)=> {
        this.categories.push({
          "id": doc.id,
          "data":doc.data()
        });
      })
    })
    .catch(error => {
      console.log('error loading ressources from firebase',error);
    })
    return this.categories;
  }
  IncNbProjet(){
    this.counterRef.doc('projet').update({
      nbprojet : firebase.firestore.FieldValue.increment(1)
    })
  }
  DecNbProjet(){
    this.counterRef.doc('projet').update({
      nbprojet : firebase.firestore.FieldValue.increment(-1)
    })
  }
  IncProjetNbInCategorie (categorie){
    this.categorieRef.doc(categorie).update({
      nbprojet: firebase.firestore.FieldValue.increment(1)
    });
  }
  DecProjetNbInCategorie (categorie){
    this.categorieRef.doc(categorie).update({
      nbprojet: firebase.firestore.FieldValue.increment(-1)
    });
  }
  supprimerCategorie(id){
    return this.categorieRef.doc(id).delete()
  }
  supprimerCover(id){
    return this.coverRef.doc(id).delete()
  }
  supprimerProjet(id){
    this.getProjetById(id)
    .then((doc)=> {
      if (doc.exists) {
        doc.data().categories.forEach((item)=> {
          this.DecProjetNbInCategorie(item);
        })
        this.counterRef.doc('projet').update({
          nbprojet : firebase.firestore.FieldValue.increment(-1)
        })
        this.projetRef.doc(id).delete();
        this.projetPrevRef.doc(id).delete();
    } else {
        console.log("No such document!");
    }
    })
    return this.coverRef.doc(id).delete();
  }
  uploudImages(images: any []){
    if (images.length){
      var urls = [];
      images.forEach(elem => {
        urls.push(this.uploadImageToCloud(elem))
      });
      return forkJoin(...urls).pipe(
        map(urls => urls.map(elem => elem['id']))
      )
    }else return of(null);
    }

  uploadImageToCloud(img){
    var formData = new FormData();
    formData.append('metadata', new Blob([JSON.stringify(this.metadata)], {type: 'application/json'}));
    formData.append('file',img);
    this.httpOptions = {
      headers : new HttpHeaders({'Authorization':  'Bearer '+ this.auth.getToken()})
    };
    return this.http.post(CLOUDINARY_URL,formData,this.httpOptions);
  }
}
