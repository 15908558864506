import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-projet-order',
  templateUrl: './projet-order.component.html',
  styleUrls: ['./projet-order.component.scss']
})
export class ProjetOrderComponent implements OnInit {
 categories = [];
 projetsPrev = [];
 projetsByCategorie = []
  constructor(private adminService:AdminService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.adminService.getCategories().then(cats =>{
      this.categories = cats;
      this.adminService.getAllPrjPrev().then(prjts =>{
        this.projetsPrev = prjts ;
        this.filterProjectByCategorie()
      });      
    });
    
  }
  filterProjectByCategorie(){
    this.categories.forEach(cat => {
      this.projetsByCategorie.push(
        this.projetsPrev.filter(prj =>{
          return prj.data.categories.includes(cat.id)
        })
      )
    })
  }

  drop(event: CdkDragDrop<string[]>,i) {
    moveItemInArray(this.projetsByCategorie[i], event.previousIndex, event.currentIndex);
  }

  onSubmit(){ 
    var promises = []
    this.projetsByCategorie.forEach(cat => {
      cat.forEach((projet,i)=> {
        if(projet.data['order'] != i )
        {
         promises.push(this.adminService.updateProjetOrder(projet.id,i)) 
        }
      })
    })
    Promise.all(promises).then(() => {
      this._snackBar.open("Modification enregistrer",'', {
        duration: 3000,
         });
    })
  }
}
